/* load fonts here to stop flicker on page load */

@font-face {
  font-family: 'ABCWhyte';
  src: url('./fonts/ABCWhyte-Bold-Trial.woff2') format('woff2'),
    url('./fonts/ABCWhyte-Bold-Trial.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABCWhyte';
  src: url('./fonts/ABCWhyte-Regular-Trial.woff2') format('woff2'),
    url('./fonts/ABCWhyte-Regular-Trial.woff') format('woff');
  font-weight: 'normal';
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABCWhyte';
  src: url('./fonts/ABCWhyte-Medium-Trial.woff2') format('woff2'),
    url('./fonts/ABCWhyte-Medium-Trial.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABCWhyteInktrap';
  src: url('./fonts/ABCWhyteInktrap-Bold-Trial.woff2') format('woff2'),
    url('./fonts/ABCWhyteInktrap-Bold-Trial.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABCWhyteInktrap';
  src: url('./fonts/ABCWhyteInktrap-Regular-Trial.woff2') format('woff2'),
    url('./fonts/ABCWhyteInktrap-Regular-Trial.woff') format('woff');
  font-weight: 'normal';
  font-style: normal;
  font-display: swap;
}
